<template>
  <Layout>
    <div class="section-content">
      <div class="form">
        <div class="body">
          <h3>Assunto: {{peticao.assunto}}</h3>
          <h3>Candidato: {{peticao.impugnadoNome}}</h3>
          <h3>Protocolo: {{peticao.protocolo}}</h3>
          <hr class="mt-4 mb-4">
          <div class="group">
            <label for="petition_situation_situacao" class="label">
              Situação da petição
            </label>
            <input id="petition_situation_situacao" type="text" class="w-full sm:w-4/12" disabled v-model="peticao.situacao" />
          </div>
          <div class="group" v-if="peticao.justificativasituacao">
            <label for="petition_situation_justificativa" class="label">
              Justificativa da Situacao
            </label>
            <textarea id="petition_situation_justificativa" class="w-full sm:w-8/12" rows="4" v-model="peticao.justificativasituacao" disabled>
            </textarea>
          </div>

          <div class="group">
            <label for="petition_situation_reponsavelNome" class="label">
              Requerente
            </label>
            <input  id="petition_situation_reponsavelNome" type="text" class="w-full sm:w-4/12" disabled v-model="peticao.responsavelNome" />
          </div>
          <div class="group">
            <label for="petition_situation_observacao" class="label">
              Observação
            </label>
            <textarea id="petition_situation_observacao" class="w-full sm:w-8/12" rows="4" v-model="peticao.observacao" disabled>
            </textarea>
          </div>
          <hr>
          <h4>ANEXOS</h4>
          <ul>
            <li v-for="(documento, index) in peticao.documentos" :key="index">
              <a target="_blank" :href="documento.url">{{'ANEXO ' + (parseInt(index)+1)}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/layout/petition/Layout.vue";

export default {
  name: "PetitionSituation",
  components: {
    Layout
  },
  data() {
    let peticao = this.$route.params.peticao || {}
    if (peticao.id) {
      try {
        peticao.documentos = JSON.parse(peticao.documentos)
      } catch (reason) {
        console.log(reason)
      }
    }
    return {
      peticao
    }
  }
};
</script>

<style scoped>

</style>
